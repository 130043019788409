import revive_payload_client_NpogFD8DwT from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wIipC5rU18 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KOLWBx90dt from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_T9xUa3gY7t from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.5.3_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import _0_siteConfig_TX0RV6bebg from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.14_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@20.14.10_sa_bv4klirqswzvebchixaa73b7em/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_J5tz80LGnA from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aC7zDY2mhG from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fwOneOUkkj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OgIGSd8tJk from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.31_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_UM2COvxjeX from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eoKHDdHJ7Y from "/vercel/path0/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.5.3_/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import titles_xXMR9TGs18 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@ty_l5bg5kiy7k5excrhk6f4y7bpdy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_do2f1kuiVe from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@ty_l5bg5kiy7k5excrhk6f4y7bpdy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_fY6zFuwAAf from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@20._husjzhw5sitesq7l52nv3syfrm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_AnELlJuTm9 from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@20._husjzhw5sitesq7l52nv3syfrm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_NFTWfdPXQl from "/vercel/path0/node_modules/.pnpm/nuxt-directus@5.6.1_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_client_ZJfltoGOVd from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import scroll_S7pZiC3d46 from "/vercel/path0/plugins/scroll.ts";
export default [
  revive_payload_client_NpogFD8DwT,
  unhead_wIipC5rU18,
  router_KOLWBx90dt,
  sentry_client_T9xUa3gY7t,
  _0_siteConfig_TX0RV6bebg,
  navigation_repaint_client_J5tz80LGnA,
  check_outdated_build_client_aC7zDY2mhG,
  chunk_reload_client_fwOneOUkkj,
  plugin_vue3_OgIGSd8tJk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UM2COvxjeX,
  plugin_eoKHDdHJ7Y,
  titles_xXMR9TGs18,
  defaults_do2f1kuiVe,
  siteConfig_fY6zFuwAAf,
  inferSeoMetaPlugin_AnELlJuTm9,
  plugin_NFTWfdPXQl,
  plugin_client_ZJfltoGOVd,
  unocss_MzCDxu9LMj,
  scroll_S7pZiC3d46
]