<script setup lang="ts">
useHead({
  titleTemplate: title => (title ? `Kovotas | ${title}` : 'Kovotas'),
})

useSeoMeta({
  ogTitle: `Kovotas`,
  ogDescription: 'Kovotas',
  ogType: 'website',
  ogImage: '/images/logo.svg',
  ogImageAlt: 'Kovotas logo',
  twitterTitle: 'Kovotas',
  twitterCard: 'summary_large_image',
  twitterImage: '/images/logo.svg',
  twitterImageAlt: 'Kovotas logo',
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
* {
  font-family: 'Kumbh Sans'
}
</style>
