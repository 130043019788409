import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91slug_93QMdSQiQcFkMeta } from "/vercel/path0/pages/kategorie/[slug].vue?macro=true";
import { default as indexvYPbR6M240Meta } from "/vercel/path0/pages/kategorie/index.vue?macro=true";
import { default as kontaktgUfu4Sf1oqMeta } from "/vercel/path0/pages/kontakt.vue?macro=true";
import { default as o_45nasUroN3GGTBZMeta } from "/vercel/path0/pages/o-nas.vue?macro=true";
import { default as _91uuid_93AMlO2WGdfWMeta } from "/vercel/path0/pages/objednavka/[uuid].vue?macro=true";
import { default as index52v1IZ46xaMeta } from "/vercel/path0/pages/objednavka/index.vue?macro=true";
import { default as _91slug_93UE1vzW9xUQMeta } from "/vercel/path0/pages/produkt/[slug].vue?macro=true";
import { default as realizacejzqM7r9vqqMeta } from "/vercel/path0/pages/realizace.vue?macro=true";
import { default as component_45stub6TJDgZKVQvMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub6TJDgZKVQv } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._kk2nmw7w5tq3gfygkfrywig5pi/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kategorie-slug",
    path: "/kategorie/:slug()",
    component: () => import("/vercel/path0/pages/kategorie/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kategorie",
    path: "/kategorie",
    component: () => import("/vercel/path0/pages/kategorie/index.vue").then(m => m.default || m)
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/vercel/path0/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: "o-nas",
    path: "/o-nas",
    component: () => import("/vercel/path0/pages/o-nas.vue").then(m => m.default || m)
  },
  {
    name: "objednavka-uuid",
    path: "/objednavka/:uuid()",
    component: () => import("/vercel/path0/pages/objednavka/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "objednavka",
    path: "/objednavka",
    component: () => import("/vercel/path0/pages/objednavka/index.vue").then(m => m.default || m)
  },
  {
    name: "produkt-slug",
    path: "/produkt/:slug()",
    component: () => import("/vercel/path0/pages/produkt/[slug].vue").then(m => m.default || m)
  },
  {
    name: "realizace",
    path: "/realizace",
    component: () => import("/vercel/path0/pages/realizace.vue").then(m => m.default || m)
  },
  {
    name: "category-page",
    path: "/kategorie/:slug/:page(\\d+)?",
    component: () => import("/vercel/path0/pages/kategorie/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stub6TJDgZKVQvMeta?.name,
    path: "/xml-feed/zbozi.xml",
    component: component_45stub6TJDgZKVQv
  },
  {
    name: component_45stub6TJDgZKVQvMeta?.name,
    path: "/xml-feed/heureka.xml",
    component: component_45stub6TJDgZKVQv
  }
]